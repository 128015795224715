<span class="ae-picker" [ngClass]="{'ae-expanded':isOpen}">
  <button [tabIndex]="-1" #labelButton tabindex="-1" type="button" role="button" class="ae-picker-label" (click)="toggleOpen($event);">{{label}}
    <svg>
      <use [attr.href]="'assets/ae-icons/icons.svg#sort'" [attr.xlink:href]="'assets/ae-icons/icons.svg#sort'"></use>
    </svg>
  </button>
  <span class="ae-picker-options">
    <span tabindex="0" role="button" class="ae-picker-item"
          *ngFor="let item of options; let i = index"
          [ngClass]="{'selected': item.value === value, 'focused': i === optionId}"
          (click)="optionSelect(item, $event)">
          {{item.label}}
    </span>
    <span class="dropdown-item" *ngIf="!options.length">No items for select</span>
  </span>
</span>
